import { type DietTag } from '@/components/diet/types';

export const TAGS: DietTag[] = [
  {
    id: '1',
    title: 'Discount 1',
    description: 'Description 1',
    discount: {
      amount: 1,
      unit: 'unit 1',
    },
    color: {
      foreground: 'brand-foreground',
      background: 'brand-background',
    },
  },
  {
    id: '2',
    title: 'Discount 2',
    description: 'Description 2',
    discount: {
      amount: 2,
      unit: 'unit 2',
    },
  },
  {
    id: '3',
    title: 'Discount 3',
    description: 'Description 3',
    discount: {
      amount: 3,
      unit: 'unit 3',
    },
  },
  {
    id: '4',
    title: 'Discount 4',
    description: 'Description 4',
    discount: {
      amount: 4,
      unit: 'unit 4',
    },
  },
  {
    id: '5',
    title: 'Discount 5',
    description: 'Description 5',
    discount: {
      amount: 5,
      unit: 'unit 5',
    },
  },
];
