import { useLocale } from 'next-intl';
import { createNavigation } from 'next-intl/navigation';
import { type Pathnames, defineRouting } from 'next-intl/routing';
import { getLocale } from 'next-intl/server';
import { type ComponentProps } from 'react';

export const locales = ['pl'] as const;

// NOTE
// Commented lines are for the English version of the website.
// Uncomment them when the English version is ready.
// + add 'en' to the locales array

export const pathnames = {
  // (public)
  '/': '/',

  '/blog': '/blog',

  '/blog/[slug]': {
    // en: '/blog/[slug]',
    pl: '/blog/[slug]',
  },

  '/diets': {
    // en: '/diets',
    pl: '/diety-pudelkowe',
  },

  '/diets/[[...slug]]': {
    // en: '/diets/[[...slug]]',
    pl: '/diety-pudelkowe/[[...slug]]',
  },

  '/diets/[slug]': {
    // en: '/offer/[slug]',
    pl: '/diety-pudelkowe/[slug]',
  },

  '/diets/[id]': {
    // en
    pl: '/dieta-pudelkowa/[id]',
  },

  '/caterings/[slug]': {
    // en: '/caterings/[slug]',
    pl: '/catering-dietetyczny/[slug]',
  },

  '/caterings/[[...slug]]': {
    // en: '/caterings/[[...slug]]',
    pl: '/cateringi-dietetyczne/[[...slug]]',
  },

  '/configure-diet/[slug]': {
    // en: '/configure-diet/[slug],
    pl: '/konfiguracja-diety/[slug]',
  },

  '/cart': {
    // en: '/cart',
    pl: '/koszyk',
  },

  '/cart/[id]': {
    // en: '/cart/[id]',
    pl: '/edycja-diety/[id]',
  },

  '/checkout': {
    // en: '/checkout',
    pl: '/zamowienie',
  },

  '/checkout/status/[id]': {
    // en: '/checkout/status/[id]',
    pl: '/zamowienie/status/[id]',
  },

  '/checkout/summary/[id]': {
    // en: '/checkout/summary/[id]',
    pl: '/zamowienie/podsumowanie/[id]',
  },

  '/privacy-policy': {
    // en: '/privacy-policy',
    pl: '/polityka-prywatnosci',
  },

  '/regulations': {
    // en: '/regulations',
    pl: '/regulamin',
  },

  '/supplier-regulations': {
    // en: '/supplier-regulations',
    pl: '/regulamin-dostawcy',
  },

  // (protected)
  '/panel': {
    // en: '/panel',
    pl: '/konto',
  },
  '/panel/orders': {
    // en: '/panel/orders',
    pl: '/konto/zamowienia',
  },
  '/panel/my-addresses': {
    // en: '/panel/my-addresses',
    pl: '/konto/moje-adresy',
  },

  // (auth)
  '/sign-in/[[...sign-in]]': {
    // en: '/sign-in/[[...sign-in]]',
    pl: '/logowanie/[[...sign-in]]',
  },

  '/sign-up/[[...sign-up]]': {
    // en: '/sign-up/[[...sign-up]]',
    pl: '/rejestracja/[[...sign-up]]',
  },
  '/multidiet-wizard': {
    // en: '/multidiet-wizard',
    pl: '/multidieta-wizard',
  },
  '/multidiet-configuration': {
    // en: '/multidiet-configuration',
    pl: '/konfiguracja-multidiety',
  },
  '/multidiet': {
    // en: '/multidiet',
    pl: '/multidieta',
  },
  '/sentenced-he': {
    // en: '/sentenced',
    pl: '/skazany',
  },
  '/sentenced-she': {
    // en: '/sentenced-she',
    pl: '/skazana',
  },
} satisfies Pathnames<typeof locales>;

export type LocalizedRoute = ComponentProps<typeof Link>['href'];

export const getLocaleForPathname = () => {
  const locale = useLocale() as (typeof locales)[number];
  return locale;
};

export const getLocaleForPathnameAsync = async () => {
  const locale = (await getLocale()) as (typeof locales)[number];
  return locale;
};

export const routing = defineRouting({
  locales,
  defaultLocale: 'pl',
  localePrefix: 'as-needed',
  pathnames: pathnames as typeof pathnames & Record<string & {}, string>,
});

export const { Link, redirect, usePathname, useRouter, getPathname } =
  createNavigation(routing);
