import { type Diet } from '@/components/diet';

export const DIETS: Diet[] = [
  {
    id: '55',
    name: 'Standard',
    imageUrl:
      'https://api.fitapetit.com.pl/uploaded-media/6304a9dc54cc8169966862.jpg',
    description:
      'Dieta Standard to idealny wyb\u00f3r dla os\u00f3b, kt\u00f3rym zale\u017cy na odpowiedniej bilansacji posi\u0142k\u00f3w. Przeznaczona dla os\u00f3b zabieganych, chc\u0105cych si\u0119 zdrowo i smacznie odzywia\u0107.\nMo\u017cliwo\u015b\u0107 wyboru da\u0144 r\u00f3wnie\u017c z diety Veggie.',
    basePrice: 52.23,
    rating: 2.56,
    catering: {
      name: 'Fit Apetit',
      logo: {
        url: 'https://api.fitapetit.com.pl/uploaded-media/5e42aea869b45617708260.png',
      },
    },
  },
  {
    id: '1',
    name: 'Slimfit',
    imageUrl:
      'https://api.fitapetit.com.pl/uploaded-media/6304aab6ebaf2558053445.jpg',
    description:
      'Catering dla tych, kt\u00f3rzy ceni\u0105 sobie przede wszystkim wygod\u0119. Chc\u0105 skutecznie dba\u0107 o zdrowie i sylwetk\u0119 ,ciesz\u0105c si\u0119 restauracyjn\u0105 jako\u015bci\u0105 da\u0144. Dieta dla ludzi bez nietolerancji pokarmowych. ',
    basePrice: 55.46,
    rating: 4.74,
    catering: {
      name: 'Fit Apetit',
      logo: {
        url: 'https://api.fitapetit.com.pl/uploaded-media/6304aab6ebaf2558053445.jpg',
      },
    },
  },
  {
    id: '56',
    name: 'Veggie',
    imageUrl:
      'https://api.fitapetit.com.pl/uploaded-media/6336912623480025692695.jpg',
    description:
      'Dieta ro\u015blinna, idealnie zbilansowana przez naszych dietetyk\u00f3w. Zawiera wszystkie niezb\u0119dne sk\u0142adniki od\u017cywcze w proporcjach odpowiadaj\u0105cych piramidzie zdrowego od\u017cywiania.\nMo\u017cliwo\u015b\u0107 wyboru da\u0144 r\u00f3wnie\u017c z diety Standard.',
    basePrice: 58.97,
    rating: 4.55,
    catering: {
      name: 'Fit Apetit',
      logo: {
        url: 'https://api.fitapetit.com.pl/uploaded-media/6304aab6ebaf2558053445.jpg',
      },
    },
  },
  {
    id: '22',
    name: 'Wyb\u00f3r Menu',
    imageUrl: null,
    description:
      'Dieta \u0022Premium\u0022 z mo\u017cliwo\u015bci\u0105 wybiera da\u0144 z innych diet',
    basePrice: 53.2,
    rating: 3.38,
    catering: {
      name: '35 klient',
      logo: {
        url: 'https://api.35klient.caterings.pl/uploaded-media/655210f32179c447347139.png',
      },
    },
  },
  {
    id: '23',
    name: 'Jesz, co chcesz - Wyb\u00f3r Menu',
    imageUrl:
      'https://api.bistrobox.pl/uploaded-media/65969d52f0037844734334.webp',
    description:
      'Dieta z wyborem da\u0144.\n* Uwaga! W przypadku wymiany da\u0144 na inne, bilans kaloryczny mo\u017ce si\u0119 r\u00f3\u017cni\u0107 od pierwotnie za\u0142o\u017conego.\nTo Ty decydujesz o finalnej warto\u015bci kalorycznej.',
    basePrice: 52.81,
    rating: 2.27,
    catering: {
      name: 'Bistro box',
      logo: {
        url: 'https://api.bistrobox.pl/uploaded-media/65969d52f0037844734334.webp',
      },
    },
    discounts: ['1', '2'],
  },
  {
    id: '12',
    name: 'Sirt Klasyczna - Dieta Adele ',
    imageUrl:
      'https://api.bistrobox.pl/uploaded-media/6597f6217b587456669242.webp',
    description:
      '\nSIRTFOOD to dieta, kt\u00f3ra jest bogata w produkty aktywuj\u0105ce geny szczup\u0142ej sylwetki i geny op\u00f3\u017aniaj\u0105ce procesy starzenia.\nCzytaj wi\u0119cej\n',
    basePrice: 60.6,
    rating: 3.64,
    catering: {
      name: 'Bistro box',
      logo: {
        url: 'https://api.bistrobox.pl/uploaded-media/6597f6217b587456669242.webp',
      },
    },
  },
  {
    id: '22',
    name: ' Sirt Wege + Ryba - Dieta Adele',
    imageUrl:
      'https://api.bistrobox.pl/uploaded-media/6597f898dc377156899481.webp',
    description:
      'SIRTFOOD to dieta, kt\u00f3ra jest bogata w produkty aktywuj\u0105ce geny szczup\u0142ej sylwetki i geny op\u00f3\u017aniaj\u0105ce procesy starzenia.\nCzytaj wi\u0119cej',
    basePrice: 55.02,
    rating: 1.99,
    catering: {
      name: 'Bistro box',
      logo: {
        url: 'https://api.bistrobox.pl/uploaded-media/6597f898dc377156899481.webp',
      },
    },
  },
  {
    id: '209',
    name: 'Zdrowy Wyb\u00f3r MINI',
    imageUrl:
      'https://api.dietific.pl/uploaded-media/6556611dd0656008563786.png',
    description: 'Codziennie 10+ da\u0144 do wyboru!',
    basePrice: 56.21,
    rating: 2.19,
    catering: {
      name: 'Dietific',
      logo: {
        url: 'https://api.dietific.pl/uploaded-media/6556611dd0656008563786.png',
      },
    },
  },
  {
    id: '193',
    name: 'Zdrowy Wyb\u00f3r MIDI',
    imageUrl:
      'https://api.dietific.pl/uploaded-media/65572615c269d319198099.png',
    description: 'Codziennie 25+ da\u0144 do wyboru!',
    basePrice: 56.25,
    rating: 2.79,
    catering: {
      name: 'Dietific',
      logo: {
        url: 'https://api.dietific.pl/uploaded-media/65572615c269d319198099.png',
      },
    },
  },
  {
    id: '190',
    name: 'Zdrowy Wyb\u00f3r MAXI',
    imageUrl:
      'https://api.dietific.pl/uploaded-media/6557262d86604255696531.png',
    description: 'Codziennie 35+ da\u0144 do wyboru!',
    basePrice: 55.22,
    rating: 1.16,
    catering: {
      name: 'Dietific',
      logo: {
        url: 'https://api.dietific.pl/uploaded-media/6557262d86604255696531.png',
      },
    },
  },
];
