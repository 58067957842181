export const GTMEventsNames = [
  'login',
  'sign_up',
  'session_start',
  'page_view',
  'add_to_cart',
  'remove_from_cart',
  'COUPON_ADDED',
  'view_cart',
  'DIET_ADD',
  'ADDRESS_ADDED',
  'begin_checkout',
  'PURCHASE_START',
  'add_payment_info',
  'PURCHASE_WAIT',
  'PURCHASE_FAIL',
  'purchase',
  'select_item',
  'view_item',
  'view_item_list',
  'diet_type_add',
  'diet_type_remove',
  'DIET_EXTENSION_START',
  'SHIPPING_CHANGED',
  'CUSTOMIZE_DIET_END',
  'CUSTOMIZE_DIET',
  'CUSTOMIZE_DIET_EDIT',
  'user_data',
] as const;
export type GTMEventsNamesType = (typeof GTMEventsNames)[number];

export type GTMCartItemType = {
  item_id: string;
  item_name: string;
  coupon?: string;
  item_brand?: string; // catering company
  item_category?: string; // main diet category
  item_category2?: string; // calories
  item_category3?: number; // timestamp first day
  item_category4?: number; // timestamp last day
  item_variant?: string; // diet witch choice
  price?: number;
  quantity?: number; // number of items
  duration?: number; // number of days
};

export type GTMEvents =
  | EventLoginType
  | EventSignUpType
  | EventAddToCartType
  | EventRemoveFromCartType
  | EventCouponAddedType
  | EventViewCartType
  | EventDietAddType
  | EventAddressAddedType
  | EventBeginCheckoutType
  | EventAddPaymentInfoType
  | EventPurchaseStartType
  | EventPurchaseWaitType
  | EventPurchaseFailType
  | EventPurchaseType
  | EventSelectItemType
  | EventViewItemType
  | EventViewItemListType
  | EventDietTypeAddType
  | EventDietTypeRemoveType
  | EventDietExtensionStartType
  | EventShippingChangedType
  | EventCustomizeDietEndType
  | EventCustomizeDietType
  | EventCustomizeDietEditType
  | EventUserDataType;

export type EventLoginType = {
  event: 'login';
  value: {
    method: 'email' | 'google' | 'facebook';
    user_id: string;
  };
};

export type EventSignUpType = {
  event: 'sign_up';
  value: EventLoginType['value'];
};

export type EventAddToCartType = {
  event: 'add_to_cart';
  ecommerce: {
    cartId?: string;
    currency: string;
    value: number;
    items: GTMCartItemType[];
  };
};

export type EventRemoveFromCartType = {
  event: 'remove_from_cart';
  ecommerce: EventAddToCartType['ecommerce'];
};

export type EventCouponAddedType = {
  event: 'COUPON_ADDED';
  ecommerce: {
    cartId?: string;
    coupon: string;
    status: 'success' | 'fail';
  };
};

export type EventViewCartType = {
  event: 'view_cart';
  ecommerce: EventAddToCartType['ecommerce'] & {
    coupon?: string;
  };
};

export type EventDietAddType = {
  event: 'DIET_ADD';
  data: undefined;
};

export type EventAddressAddedType = {
  event: 'ADDRESS_ADDED';
  user_data: {
    geo: string; // postal code
    city: string; // no polish letters, no special characters
  };
};

export type EventBeginCheckoutType = {
  event: 'begin_checkout';
  ecommerce: EventViewCartType['ecommerce'];
};

export type EventPurchaseStartType = {
  event: 'PURCHASE_START';
  ecommerce: EventBeginCheckoutType['ecommerce'] & {
    transaction_id: string;
    shipping: number; // shipping cost value
    discount: number; // discount value
    value: number; // value gross
  };
};

export type EventAddPaymentInfoType = {
  event: 'add_payment_info';
  data: EventViewCartType['ecommerce'] & {
    payment_type: 'szybki_blik' | 'payu';
  };
};

export type EventPurchaseWaitType = {
  event: 'PURCHASE_WAIT';
  ecommerce: EventPurchaseStartType['ecommerce'];
};

export type EventPurchaseFailType = {
  event: 'PURCHASE_FAIL';
  ecommerce: EventPurchaseStartType['ecommerce'];
};

export type EventPurchaseType = {
  event: 'purchase';
  ecommerce: EventPurchaseStartType['ecommerce'];
};

export type EventSelectItemType = {
  event: 'select_item';
  data: GTMCartItemType;
};

export type EventViewItemType = {
  event: 'view_item';
  ecommerce: {
    currency: string;
    value: number;
    items: GTMCartItemType[];
  };
};

export type EventViewItemListType = {
  event: 'view_item_list';
  data: {
    item_list_name: 'single_diet' | 'multidiet';
    diet_type: string;
    duration: number;
    meals_count: number;
    kcal: number;
    search_term?: string;
    city?: string;
    type?: boolean; // TBD
    price_from?: number;
    price_to?: number;
    melas_choice: boolean;
    promo?: boolean;
    filtr?: 'diety' | 'cateringi';
    sort?: string;
    items: GTMCartItemType[];
  };
};

export type EventDietTypeAddType = {
  event: 'diet_type_add';
  data: {
    type: string;
  };
};

export type EventDietTypeRemoveType = {
  event: 'diet_type_remove';
  data: {
    type: string;
  };
};

export type EventDietExtensionStartType = {
  event: 'DIET_EXTENSION_START';
  data: {
    items: GTMCartItemType[];
  };
};

export type EventShippingChangedType = {
  event: 'SHIPPING_CHANGED';
  data: {
    items: GTMCartItemType[];
  };
};

export type EventCustomizeDietEndType = {
  event: 'CUSTOMIZE_DIET_END';
  ecommerce: EventViewItemType['ecommerce'];
};

export type EventCustomizeDietType = {
  event: 'CUSTOMIZE_DIET';
  ecommerce: {
    items: {
      item_id: string;
      item_name: string;
      item_brand?: string;
      item_category?: string;
    }[];
  };
};

export type EventCustomizeDietEditType = {
  event: 'CUSTOMIZE_DIET_EDIT';
  ecommerce: EventCustomizeDietType['ecommerce'];
};

export type EventUserDataType = {
  event: 'user_data';
  value: {
    method: 'email' | 'google' | 'facebook' | null;
    user_id: string | null;
    page: string;
  };
};
