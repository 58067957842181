import {
  type GTMEvents,
  GTMEventsNames,
  type GTMEventsNamesType,
} from '../types/event.type';
import { sendGTMEvent } from '@next/third-parties/google';

type DataLayerType = {
  reset: () => void;
  get: (a: string) => any;
  name: string;
  set: (a: string, b: string) => void;
};

export const sendEvent = <T extends GTMEvents>(event: T) => {
  if (localStorage.getItem('ga_debug_mode') === 'true') {
    return;
  }

  if (typeof window !== 'undefined') {
    window.dataLayer?.push(function (this: DataLayerType) {
      this.reset();
    });
  }

  sendGTMEvent(event);
};

export const checkEventType = (
  eventName: string,
): eventName is GTMEventsNamesType =>
  GTMEventsNames.includes(eventName as GTMEventsNamesType);
