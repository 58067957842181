import pino, { type Logger } from 'pino';

export const logger: Logger = pino({
  level: process.env.PINO_LOG_LEVEL || 'info',
  // prevent logging of sensitive data
  // add more paths as needed
  redact: {
    paths: [
      'body.items[*].contact.*',
      'body.items[*].address.*',
      'response.items[*].configurationItem.contact.*',
      'response.items[*].configurationItem.address.*',
      'response.invoiceData.*',
    ],
    censor: '***',
  },
});
