import {
  type LocalizedRoute,
  getLocaleForPathname,
  getPathname,
} from '@/i18n/navigation';
import { type NavigationItem } from '@/types/navigation-link.type';

export const getNavigationItems = (): NavigationItem[] => {
  const locale = getLocaleForPathname();
  return [
    {
      label: 'diets',
      href: getPathname({
        href: { pathname: '/diets/[[...slug]]', params: { slug: [] } },
        locale,
      }) as LocalizedRoute,
    },
  ];
};
