import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

/**
 * Format price function. Default currency is PLN and locale is pl-PL.
 * @example
 * formatPrice(1000) // 1000,00 zł
 * formatPrice(1.234) // 1,23 zł
 * formatPrice(1.235) // 1,24 zł
 * formatPrice(20.1) // 20,10 zł
 * formatPrice(12.34 + 56.78) // 69,12 zł
 * formatPrice(12, { currency: "USD" }) // 12,00 USD
 * formatPrice(12, { currency: "USD", locale: "en-US" }) // $12.00
 * formatPrice(12, { currency: "USD", locale: "de-DE" }) // 12,00 $
 * formatPrice(12, { currency: "PLN" }) // 12,00 zł
 * @param price {number} - price to format
 * @param [options] {object} - options object with currency and locale
 * @returns
 */
export const formatPrice = (
  price: number,
  options?: { currency?: string; locale?: string },
) => {
  const { currency = 'PLN', locale = 'pl-PL' } = options || {};
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
    .format(price)
    .replace(/\xa0/g, ' ')
    .replace(/\u202f/g, ' ');
};

export const formatFilterValue = (value: string | string[] | undefined) => {
  if (Array.isArray(value)) {
    if (value.length > 3) return `${value.length} selected`;
    return value.join(', ');
  }
  return value;
};
