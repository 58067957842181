export const DIET_FILTERS = [
  'All',
  'Vegetarian',
  'Vegan',
  'Gluten Free',
  'Ketogenic',
  'Paleo',
  'Primal',
  'Whole30',
];

export const CALORIES_FILTERS = [
  {
    label: 'less_than_1300',
    value: '0,1299',
  },
  {
    label: '1300',
    value: '1300',
  },
  {
    label: '1600',
    value: '1600',
  },
  {
    label: '1800',
    value: '1800',
  },
  {
    label: '2000',
    value: '2000',
  },
  {
    label: '2500',
    value: '2500',
  },
  {
    label: 'more_than_2500',
    value: '2501,6000',
  },
];
export const MEALS_FILTERS = [1, 2, 3, 4, 5];
