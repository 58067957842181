import { CALORIES_FILTERS } from '@/data';
import { dietsPresets } from '@/features/diets/data/presets';
import { type DietCatalogAvailabilityType } from '@/features/diets/types';
import { type FiltersType } from '@/types/filters.type';
import { type SelectType } from '@/types/forms';
import { addDays, format, isBefore, parse } from 'date-fns';
import { pl } from 'date-fns/locale';

export const querify = (url: string, params: Record<string, string>) => {
  const searchParams = new URLSearchParams(params);
  return `${url}?${searchParams.toString()}`;
};

export const generateRandomUUID = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

/**
 * Converts an object to a query string.
 *
 * @param {T} data - The object to be converted to a query string.
 * @return {string} The query string representation of the object.
 */
export const objectToQueryString = <T>(data: T, firstMark = '?'): string => {
  if (data) {
    Object.keys(data).forEach(
      (key) =>
        (data[key as keyof T] === undefined || data[key as keyof T] === '') &&
        delete data[key as keyof T],
    );
    const queryString = encodeQueryString(data);

    return firstMark + queryString;
  }

  return '';
};

/**
 * Encodes an object into a query string.
 *
 * @param {object} object - The object to encode.
 * @return {string} The encoded query string.
 */
export const encodeQueryString = (object: object): string => {
  /**
   * Reduces an object into an array of query string parameters.
   *
   * @param obj - The object to reduce.
   * @param parentPrefix - The parent prefix for the current key.
   * @returns An array of query string parameters.
   */
  function reducer(
    obj: Record<string, any>,
    parentPrefix?: string | null,
  ): (prev: string[], key: string) => string[] {
    return function (prev: string[], key: string): string[] {
      const val = obj[key];
      key = encodeURIComponent(key);
      const prefix = parentPrefix ? `${parentPrefix}[${key}]` : key;

      if (val == null || typeof val === 'function') {
        prev.push(`${prefix}=`);
        return prev;
      }

      if (typeof val === 'boolean') {
        prev.push(`${prefix}=${val.toString()}`);
        return prev;
      }

      if (typeof val === 'number' || typeof val === 'string') {
        prev.push(`${prefix}=${encodeURIComponent(val.toString())}`);
        return prev;
      }

      prev.push(Object.keys(val).reduce(reducer(val, prefix), []).join('&'));
      return prev;
    };
  }

  return Object.keys(object).reduce(reducer(object), []).join('&');
};

/**
 * Generates a pagination range based on the current page number, the total number of pages, and an optional delta value.
 *
 * @param {number} current - The current page number.
 * @param {number} last - The total number of pages.
 * @param {number} [delta=2] - The number of pages to show on each side of the current page.
 * @return {number[]} An array containing the pagination range.
 */
export const getPaginationArray = (
  current: number,
  last: number,
  delta = 2,
) => {
  if (last === 1) return [1];

  const left = current - delta,
    right = current + delta + 1,
    range = [];

  for (let i = 1; i <= last; i++) {
    if (i == 1 || i == last || (i >= left && i < right)) {
      if (i === left && i > 2) {
        range.push('...');
      }

      range.push(i);

      if (i === right - 1 && i < last - 1) {
        range.push('...');
      }
    }
  }

  return range;
};

/**
 * Returns an array of strings extracted from the specified field of each object in the given array of objects.
 *
 * @param {Array<{ [key: string]: any }>} objects - The array of objects from which to extract the field values.
 * @param {string} field - The name of the field to extract from each object.
 * @return {Array<string>} An array of strings representing the extracted field values.
 */
export const getFieldValueFromObjectArray = (
  objects: { [key: string]: any }[],
  field: string,
): string[] => {
  return objects.map((obj) => obj[field]?.toString() || '');
};

/**
 * Creates a path string from the values of the specified object.
 *
 * @param {object} obj - The object from which to extract values for the path.
 * @return {string} The path string created from the object values.
 */
export const createPathFromObject = <T extends Record<string, any>>(
  obj: Partial<T>,
  sufix?: Partial<Record<keyof T, string>>,
): string => {
  const filteredValues = Object.entries(obj)
    .filter(([_, value]) => value !== undefined)
    .map(([key, value]) => {
      if (sufix && sufix[key as keyof T]) {
        return `${value}_${sufix[key as keyof T]}`;
      }
      return value;
    });
  return filteredValues.join('/');
};

export const removeUndefinedProperties = <T extends FiltersType>(
  obj: T,
): Partial<T> => {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => value !== undefined),
  ) as Partial<T>;
};

export const extractNumberFromPageParams = (str: string): number | null => {
  const match = str.match(/^(\d+)_/);
  return match && Number(match[1]);
};

export type ParamObject = {
  name: string;
  value: string | string[];
};
/**
 * Parses the search parameters and returns an array of ParamObjects.
 *
 * @param {URLSearchParams} searchParams - The search parameters to parse.
 * @return {ParamObject[]} The array of ParamObjects with name and value properties.
 */
export const parseSearchParams = (
  searchParams: URLSearchParams,
): ParamObject[] => {
  const result: ParamObject[] = [];

  searchParams.forEach((value, key) => {
    if (value.includes(',')) {
      result.push({
        name: key,
        value: value.split(','),
      });
    } else {
      result.push({
        name: key,
        value: value,
      });
    }
  });

  return result;
};

type FlattenedParamObject = {
  name: string;
  value: string;
};
/**
 * Reduces the searchParams array by flattening the values into an array of objects.
 *
 * @param {ParamObject[]} searchParams - The array of parameters to flatten.
 * @return {FlattenedParamObject[]} The flattened array of objects with name and value properties.
 */
export const flattenSearchParams = (
  searchParams: ParamObject[],
  exclude: string[] = [],
): FlattenedParamObject[] => {
  return searchParams.reduce(
    (acc: FlattenedParamObject[], param: ParamObject) => {
      // Skip if the param name is in the exclude array
      if (exclude.includes(param.name)) {
        return acc;
      }

      // do not join values for caloricity if it is array

      if (param.name === 'caloricity' && Array.isArray(param.value)) {
        const [min, max] = param.value;
        if (min === CALORIES_FILTERS[0]!.value.split(',')[0]) {
          acc.push({ name: param.name, value: '<1300' });
        } else if (
          max ===
          CALORIES_FILTERS[CALORIES_FILTERS.length - 1]!.value.split(',')[1]
        ) {
          acc.push({ name: param.name, value: '>2500' });
        } else {
          acc.push({ name: param.name, value: param.value.join('-') });
        }
        return acc;
      }

      if (Array.isArray(param.value)) {
        param.value.forEach((value: string) => {
          acc.push({ name: param.name, value });
        });
      } else {
        acc.push({ name: param.name, value: param.value });
      }
      return acc;
    },
    [],
  );
};

/**
 * Removes a specific search parameter from the URL search parameters.
 *
 * @param {URLSearchParams} urlSearchParams - The URL search parameters object.
 * @param {ParamObject} paramToRemove - The search parameter to remove.
 * @returns {URLSearchParams} The updated URL search parameters object.
 */
export const removeSearchParam = (
  urlSearchParams: URLSearchParams,
  paramToRemove: ParamObject,
): URLSearchParams => {
  const values: string[] =
    urlSearchParams.get(paramToRemove.name)?.split(',') || [];

  if (values.length > 0) {
    urlSearchParams.delete(paramToRemove.name);
    if (values.length > 1) {
      const updatedValues = values.filter(
        (value: string) => value !== paramToRemove.value,
      );
      if (updatedValues.length > 0) {
        urlSearchParams.set(paramToRemove.name, updatedValues.join(','));
      }
    }
  }

  return urlSearchParams;
};

interface QueryParams {
  [key: string]: string | number | string[] | undefined | SelectType[];
}
export const objectToQueryParams = <T extends QueryParams>(
  obj: T,
  existingParams: URLSearchParams,
): URLSearchParams => {
  const params = new URLSearchParams(existingParams);

  Object.entries(obj).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      if (value.length === 0) {
        params.delete(key);
        return;
      }
      if (
        value.length > 0 &&
        typeof value[0] === 'object' &&
        'value' in value[0]
      ) {
        const selectValues = (value as SelectType[])
          .map((select) => select.value)
          .join(',');
        params.set(key, selectValues);
      } else {
        params.set(key, (value as string[]).join(','));
      }
    } else {
      if (value !== undefined && value !== '') {
        params.set(key, value.toString());
      } else {
        params.delete(key);
      }
    }
  });

  return params;
};

export type FilterableParams = Record<string, string | string[]>;

export const getConfiguratorParams = <
  T extends FilterableParams | URLSearchParams,
>(
  input?: T,
) => {
  if (!input) return {};

  const keysToKeep = ['days', 'caloricity', 'preset', 'configuration'] as const;

  // Convert input to entries regardless of the type
  const entries =
    input instanceof URLSearchParams
      ? Array.from(input.entries())
      : Object.entries(input);

  return entries.reduce(
    (acc, [key, value]) => {
      if (!keysToKeep.includes(key as (typeof keysToKeep)[number])) {
        return acc;
      }

      if (key === 'preset') {
        return {
          ...acc,
          numberOfMeals: dietsPresets[value as keyof typeof dietsPresets],
        };
      }

      return {
        ...acc,
        [key]: value,
      };
    },
    {} as Record<string, string | number | string[]>,
  );
};

export const calculateAvailabilityDate = (
  inputObj: DietCatalogAvailabilityType,
  returnedFormat: 'date' | 'day' = 'day',
): Date | string => {
  const currentTime = new Date();

  const targetTime = parse(
    format(currentTime, 'yyyy-MM-dd') + ` ${inputObj.hour}`,
    'yyyy-MM-dd HH:mm',
    new Date(),
  );

  const deltaDays = isBefore(currentTime, targetTime)
    ? inputObj.days
    : inputObj.days + 1;

  const targetDate = addDays(currentTime, deltaDays);

  if (returnedFormat === 'day') {
    return format(targetDate, 'eeee', {
      locale: pl,
    });
  }

  return targetDate;
};

export const trimToWords = (inputString: string, wordLimit = 10) => {
  if (!inputString) return '';

  const words = inputString.split(' ');
  if (words.length <= wordLimit) {
    return inputString;
  }

  const trimmedWords = words.slice(0, wordLimit);
  return trimmedWords.join(' ') + '...';
};

export const getParamFromUrlString = (url: string, param: string) => {
  const queryIndex = url.indexOf('?');
  const queryString = queryIndex !== -1 ? url.substring(queryIndex) : '';
  const urlParams = new URLSearchParams(queryString);

  return urlParams.get(param);
};

export const searchParamsToObject = (searchParams: {
  [key: string]: string;
}): Record<string, string | string[]> => {
  const params: Record<string, string | string[]> = {};

  Object.entries(searchParams).forEach(([key, value]) => {
    const cleanKey = key.replace(/\[\d+\]$/, '');

    if (params[cleanKey]) {
      if (!Array.isArray(params[cleanKey])) {
        params[cleanKey] = [params[cleanKey] as string];
      }
      (params[cleanKey] as string[]).push(value);
    } else {
      params[cleanKey] = value;
    }
  });

  return params;
};

export const parseSearchParamsToObject = (
  searchParams: URLSearchParams,
): Record<string, any> => {
  const result: Record<string, any> = {};

  searchParams.forEach((value, key) => {
    const match = key.match(/^(\w+)\[(\d+)\]$/);

    if (match && match[1] && match[2]) {
      const arrayKey = match[1];
      const index = parseInt(match[2], 10);

      if (!result[arrayKey]) {
        result[arrayKey] = [];
      }
      result[arrayKey][index] = value;
    } else {
      result[key] = value;
    }
  });

  return result;
};
