import { logger } from './logger';
import { getAuthToken } from './server/get-auth-token';

/**
 * @type T - type of the response
 * @type B - type of the body
 * @param method - HTTP method (GET, POST, PUT, DELETE)
 * @param url - URL to fetch
 * @param body - body to send of type B
 * @returns Promise<T>
 */
const METHOD = async <T, B>({
  method,
  url,
  body,
  params,
  callback,
  log = true,
}: {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  url: string;
  body?: B;
  params?: RequestInit;
  callback?: (response: Response) => Promise<T>;
  log?: boolean;
}): Promise<T> => {
  let token = null;

  if (
    (process.env.FOOAPP_URL && url.includes(process.env.FOOAPP_URL)) ||
    (process.env.NEXT_PUBLIC_FOOAPP_URL &&
      url.includes(process.env.NEXT_PUBLIC_FOOAPP_URL))
  ) {
    token = await getAuthToken();
  }

  const { headers, ...rest } = params || {};

  const authHeaders = {
    ...(headers || {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
    ...(token && { Authorization: `Bearer ${token}` }),
  };

  let resStatus = 0;

  return fetch(url, {
    method: method,
    headers: authHeaders,
    body: body && JSON.stringify(body),
    ...rest,
  })
    .then(async (response) => {
      resStatus = response.status;
      if (callback) {
        return callback(response);
      }
      if (!response.ok) {
        const errorResponse = await response.json().catch(() => ({
          message: 'Could not parse error response as JSON',
        }));
        throw new Error(`Something went wrong: ${response.statusText}`, {
          cause: { status: response.status, ...errorResponse },
        });
      }
      if (response.status === 204) return Promise.resolve(null as unknown as T);
      return response.json() as Promise<T>;
    })
    .then((data) => {
      if (log) {
        logger.info({ status: resStatus, method, url, body, response: data });
      }
      return data;
    })
    .catch((error: Error) => {
      logger.error({ status: resStatus, method, url, body, response: error });
      console.error(`Fetching data from ${url} failed:`, error);
      return Promise.reject(error.cause || error);
    });
};

/**
 * @param url - URL to fetch
 * @returns Promise<T>
 */
export const GET = async <T>({
  url,
  params,
  callback,
  log = false,
}: {
  url: string;
  params?: RequestInit;
  callback?: (response: Response) => Promise<T>;
  log?: boolean;
}): Promise<T> =>
  METHOD<T, null>({ method: 'GET', url, params, callback, log });

/**
 * @param url - URL to fetch
 * @param body - body to send of type B
 * @returns Promise<T>
 * @type T - type of the response
 * @type B - type of the body
 */
export const POST = async <T, B>({
  url,
  body,
  params,
  log = false,
}: {
  url: string;
  body: B;
  params?: RequestInit;
  log?: boolean;
}): Promise<T> => METHOD<T, B>({ method: 'POST', url, body, params, log });

/**
 * @param url - URL to fetch
 * @param body - body to send of type B
 * @returns Promise<T>
 * @type T - type of the response
 * @type B - type of the body
 */
export const PUT = async <T, B>({
  url,
  body,
  log = false,
}: {
  url: string;
  body: B;
  log?: boolean;
}): Promise<T> => METHOD<T, B>({ method: 'PUT', url, body, log });

/**
 * @param url - URL to fetch
 * @returns Promise<T>
 * @type T - type of the response
 */
export const DELETE = async <T>(url: string): Promise<T> =>
  METHOD<T, null>({ method: 'DELETE', url });
